import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import ArticleBlogCarte from '../blog/ArticleBlogCarte';
import JPSMetaview from './JPSMetaview';


// carrousel banner present in every page except /nos-articles  ,  /contact  ,  /article
const API_BASE_URL = 'https://passionate-book-96c97196c7.strapiapp.com/api';

const CarrouselBlog = ({ sectionId }) => {
  const [articles, setArticles] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // fetch fonction
  const fetchData = async (url) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      });
      return response.data.data;
    } catch (error) {
      setError('Une erreur est survenue lors du chargement des articles.');
      console.error('Erreur lors de la récupération des données:', error);
      return null;
    }
  };

  // fetch data
  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      try {
        let url = `${API_BASE_URL}/nos-articles?populate=*`;
        
        if (sectionId) {
          url += `&filters[section][id][$eq]=${sectionId}`;
        }

        const articlesResponse = await fetchData(url);
        if (articlesResponse) {
          const sortedArticles = articlesResponse.sort((a, b) => b.id - a.id);
          setArticles(sortedArticles);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des articles:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, [sectionId]);

  //carrousel slide 
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 2) % articles.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 2 + articles.length) % articles.length);
  };

  const article1 = articles[currentIndex];
  const article2 = articles[(currentIndex + 1) % articles.length];

  //Fade in animations
  const opacityVariants = {
    initial: { opacity: 0 },
    enter: { opacity: 1, transition: { duration: 1 } },
  };

  const { ref: jpsMetaviewRef, inView: isInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (articles.length === 0) return null;

  return (
    <div className="carrousel-blog">
      <h2 className="carrousel-blog__title">NOS ARTICLES</h2>
      <button aria-label="chevron left" className="carrousel-blog__prev" onClick={handlePrev}>
        <i className="fas fa-chevron-left"></i>
      </button>
      <motion.div
        className="carrousel-blog__content"
        ref={jpsMetaviewRef}
        initial={{ opacity: 0 }}
        animate={{ opacity: isInView ? 1 : 0 }}
        transition={{ duration: 1.5, delay: 0.2 }}
      >
        <AnimatePresence>
          {article1 && (
            <motion.div
              key={`${article1.id}-1`}
              variants={opacityVariants}
              initial="initial"
              animate="enter"
            >
              <ArticleBlogCarte article={{
                id: article1.id,
                imageUrl: article1.attributes.MediaBandeau?.data?.attributes?.url || '/default-image.png',
                filtre: article1.attributes.Filtre || 'Sans filtre',
                readingTime: `${Math.ceil((article1.attributes.BlocTexteUn?.[0]?.children?.[0]?.text?.length || 0) / 150)} min`,
                titre: article1.attributes.Titre || 'Titre indisponible',
                listeSections: article1.attributes.ListeSections?.map(section => section.children[0].text) || [],
                descriptif: article1.attributes.BlocTexteUn?.[0]?.children?.[0]?.text || 'Aucun descriptif disponible',
                slug: article1.attributes.slug || ''
              }} />
            </motion.div>
          )}
          {article2 && (
            <motion.div
              key={`${article2.id}-2`}
              variants={opacityVariants}
              initial="initial"
              animate="enter"
            >
              <ArticleBlogCarte article={{
                id: article2.id,
                imageUrl: article2.attributes.MediaBandeau?.data?.attributes?.url || '/default-image.png',
                filtre: article2.attributes.Filtre || 'Sans filtre',
                readingTime: `${Math.ceil((article1.attributes.BlocTexteUn?.[0]?.children?.[0]?.text?.length || 0) / 150)} min`,
                titre: article2.attributes.Titre || 'Titre indisponible',
                listeSections: article2.attributes.ListeSections?.map(section => section.children[0].text) || [],
                descriptif: article2.attributes.BlocTexteUn?.[0]?.children?.[0]?.text || 'Aucun descriptif disponible',
                slug: article2.attributes.slug || ''
              }} />
            </motion.div>
          )}
        </AnimatePresence>
        <div className="jps-metaview-wrapper">
          <JPSMetaview />
        </div>
      </motion.div>
      <button aria-label="chevron right" className="carrousel-blog__next" onClick={handleNext}>
        <i className="fas fa-chevron-right"></i>
      </button>
    </div>
  );
};

export default CarrouselBlog;


import React, { useState, useEffect} from 'react';
import axios from 'axios';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import ArticleBlogCarte from '../components/blog/ArticleBlogCarte';
import JPSMetaview from '../components/commun/JPSMetaview';
import Header from '../components/commun/Header';
import QuestionProjet from '../components/commun/QuestionProjet';
import Footer from '../components/commun/Footer';
import BoutonsDevisRdvWhatsapp from '../components/commun/BoutonsDevisRdvWhatsapp';
import Erreur from '../components/commun/Erreur';
import { Helmet } from 'react-helmet';

const API_BASE_URL = 'https://passionate-book-96c97196c7.strapiapp.com/api';

const NosArticles = ({ customStyles }) => {
  const [articles, setArticles] = useState([]);
  const [filters, setFilters] = useState([]);
  const [activeFilter, setActiveFilter] = useState('Tous');
  const [animationKey, setAnimationKey] = useState(0);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState('Toutes les catégories');
  const [isFixed, setIsFixed] = useState(false);
  const [isMobile] = useState(window.innerWidth <= 768);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  //fade in animation
  const { ref: jpsMetaviewRef, inView: isJpsMetaviewInView } = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  const { ref: footerRef, inView: isFooterView } = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  //fetch fonction
  const fetchData = async (url) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      });
      return response.data.data;
    } catch (error) {
      setError('Une erreur est survenue lors du chargement des données.');
      console.error('Erreur lors de la récupération des données:', error);
      return null;
    }
  };

  //fetch data
  useEffect(() => {
    const fetchFilters = async () => {
      setLoading(true);
      try {
        const response = await fetchData(`${API_BASE_URL}/nos-articles-filtres?populate=*`);
        const filterData = response ? response.map((filter) => filter.attributes.Titre) : [];
        setFilters([...filterData]);
      } catch (error) {
        console.error('Error fetching filters:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchArticles = async () => {
      setLoading(true);
      try {
        const response = await fetchData(`${API_BASE_URL}/nos-articles?populate=*&pagination[pageSize]=50`);
        if (response) {
          const sortedArticles = response.sort((a, b) => b.id - a.id);
          setArticles(sortedArticles || []);
        }        
      } catch (error) {
        console.error('Error fetching articles:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFilters();
    fetchArticles();
  }, []);

  useEffect(() => {
  const handleScroll = () => {
    const jpsMetaview = jpsMetaviewRef.current;
    const header = document.querySelector('header');
    const footer = footerRef.current;
    const questionProjet = document.querySelector('.question-projet'); // Sélection du QuestionProjet

    const headerHeight = header ? header.offsetHeight : 100;

    if (jpsMetaview && header && footer && questionProjet) {
      const headerBottom = header.getBoundingClientRect().bottom + window.scrollY;
      const jpsMetaviewTop = jpsMetaview.getBoundingClientRect().top + window.scrollY;
      const footerTop = footer.getBoundingClientRect().top + window.scrollY;
      const questionProjetTop = questionProjet.getBoundingClientRect().top + window.scrollY;

      const adjustedTop = jpsMetaviewTop - (headerHeight + 25);

      if (window.scrollY >= adjustedTop && headerBottom < jpsMetaviewTop) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }

      if (window.scrollY >= questionProjetTop) {
        console.log("JPSMetaview atteint le top de QuestionProjet");
      }

      if (window.scrollY >= footerTop) {
        console.log("JPSMetaview atteint le top du footer");
      }
    }
  };

  window.addEventListener('scroll', handleScroll);
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, [isFooterView, jpsMetaviewRef, footerRef]);

  //Filter fonctions
  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
    setSelectedSection(filter);
    setIsFilterMenuOpen(false);
    setAnimationKey((prevKey) => prevKey + 1);
  };

  const filteredArticles = articles.filter(
    (article) => activeFilter === 'Tous' || article.attributes.Filtre === activeFilter
  );

  //fade in animation
  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const getAnimationDelay = (index) => index * 0.2;

  if (loading) return <div></div>;
  if (error) return <div><Erreur /></div>;

  return (
    <div className="nos-articles">
      <Helmet>
        <title>{activeFilter === 'Tous' ? 'Nos Articles' : `Articles - ${activeFilter}`}</title>
        <meta name="description" content="Découvrez nos articles sur différents sujets." />
        <meta property="og:title" content="Nos Articles" />
        <meta property="og:description" content="Découvrez nos articles sur différents sujets." />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <Header />

      {/* Filter section */}
      <header className="nos-articles__header">
        {isMobile ? (
          <>
            <div className="filter-header" onClick={() => setIsFilterMenuOpen(!isFilterMenuOpen)}>
              <h4>{selectedSection}</h4>
              <span
                className="chevron"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsFilterMenuOpen(!isFilterMenuOpen);
                }}
              >
                <i className={`fas fa-chevron-${isFilterMenuOpen ? 'up' : 'down'}`}></i>
              </span>
            </div>
            {isFilterMenuOpen && (
              <div className="filter-dropdown">
                {filters.map((filter) => (
                  <span
                    key={filter}
                    onClick={() => handleFilterClick(filter)}
                    className={`nos-articles__filter ${activeFilter === filter ? 'active' : ''}`}
                  >
                    {filter}
                  </span>
                ))}
              </div>
            )}
          </>
        ) : (
          <div className="nos-articles__filters">
            {filters.map((filter) => (
              <span
                key={filter}
                className={`nos-articles__filter ${activeFilter === filter ? 'active' : ''}`}
                onClick={() => handleFilterClick(filter)}
              >
                {filter}
              </span>
            ))}
          </div>
        )}
      </header>

      {/* Main Article section */}
      <div className="nos-articles__content">
        <main className="nos-articles__main">
          <div className="nos-articles__grid">
            {filteredArticles.map((article, index) => (
              <motion.div
                key={`${article.id}-${animationKey}`}
                className="nos-articles__card"
                initial="hidden"
                animate="visible"
                variants={cardVariants}
                transition={{ duration: 0.6, delay: getAnimationDelay(index) }}
              >
                <ArticleBlogCarte
                  article={{
                    id: article.id,
                    imageUrl:
                      article.attributes.MediaBandeau?.data?.attributes?.url || '/default-image.png',
                    filtre: article.attributes.Filtre || 'Sans filtre',
                    readingTime: `${Math.ceil(
                      (article.attributes.BlocTexteUn?.[0]?.children?.[0]?.text?.length || 0) / 150
                    )} min`,
                    titre: article.attributes.Titre || 'Titre indisponible',
                    listeSections: article.attributes.ListeSections?.map(
                      (section) => section.children[0]?.text
                    ) || [],
                    descriptif:
                      article.attributes.BlocTexteUn?.[0]?.children?.[0]?.text ||
                      'Aucun descriptif disponible',
                    slug: article.attributes.slug || '',
                  }}
                />
              </motion.div>
            ))}

            {/* Aside Section for Mobile */}
            {isMobile && (
              <div ref={jpsMetaviewRef} className="jps-metaview-wrapper">
                <JPSMetaview />
              </div>
            )}
          </div>
        </main>

        {/* Aside Section for Desktop */}
        {!isMobile && (
          <aside className={`nos-articles__aside ${isFixed ? 'fixed' : ''}`}>
              <div ref={jpsMetaviewRef} className={`jps-metaview-wrapper ${isJpsMetaviewInView ? 'jps-metaview-fixed' : ''} ${isFooterView ? 'jps-metaview-stopped' : ''}`}>
                <JPSMetaview />
              </div>
            <BoutonsDevisRdvWhatsapp customStyles={customStyles?.floatingButtons} />
          </aside>
        )}
      </div>
      <QuestionProjet />
      <div ref={footerRef}>
        <Footer />
      </div>
    </div>
  );
}

export default NosArticles;